import React,{useEffect, useState} from "react";
import Header from "../../ReuseComponents/Header/Header";
import { Backdrop, Button, Card, CircularProgress, Grid, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import { Box, textAlign } from "@mui/system";
import ScanImage from "../../../Assets/images/QrScan/qrscanimg.svg"
import { useLocation, useParams } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import ScanImageVerify from "../../../Assets/images/QrScan/qrscanimg.svg"
import InqueImg from "../../../Assets/images/QrScan/inqueImage.svg"
import InvalidImg from "../../../Assets/images/QrScan/invalidImg.svg"
import MissedImg from "../../../Assets/images/QrScan/missedImg.svg"
import CancelledImg from "../../../Assets/images/QrScan/cancelImg.svg"
import OnDateImg from "../../../Assets/images/QrScan/ondateImg.svg"
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import LogoImae from "../../../Assets/images/QrScan/logoscan.svg";
import NoAppointmentsImg from "../../../Assets/images/QrScan/noappointments.svg";
import CancelImg from "../../../Assets/images/QrScan/cancel.svg"
import UtilDateTime from "../../../Constant/utillDateTime";
import validationRegExp from "../../../Configuration/ValidationConfig";
import ThankYouImg from "../../../Assets/images/QrScan/thankyouImg.svg";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import dayjs from "dayjs";
import { BasicMenuItem, BasicSelect } from "../../ReuseComponents/Select/BasicSelect";

export default function PatientScanQr() {
    const {doctor_uid} = useParams();
    const [userdata, setUserdata] = useState();
    const [enterMobileNum, setEnterMobileNum] = useState(true);
    
    const [verifyPage , setVerifyPage ] = useState(false);
    const [inQueData, setInQueData] = useState(false);
    const [invalidStatus, setInvalidStatus] = useState(false);
    const [missedStatus, setMissedStatus] = useState(false);
    const [cancelledStatus, setCancelledStatus ] = useState(false);
    const [onDateStatus, setOnDateStatus] = useState(false);
    const [onDateShowMsg, setOnDateShowMsg] = useState("");
    const [onTimeShow, setOnTimeShow] = useState("");
    const [newNumber, setNewNumber] = useState(false);
    const [noAppointments, setNoAppointments] = useState(false);
    const [yourName, setYourName] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false);
    const [cancelStatus, setCancelStatus] = useState(false);
    const [usingMobileSlot, setUsingMobileSlot] = useState(false);
    const [filterDataList, setFilterDataList] = useState({});
    const [patientNameError, setPatientNameError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [thankYouPage, setThankYouPage] = useState(false);
    const [alreadyInque, setAlreadyInque] = useState(false);
    const [errorAccord, setErrorAccord] = useState(false);
    const [checkInStatus, setCheckInStatus] = useState(false);
    const [alreadyAvailMessage, setAlreadyAvailMessage] = useState("")
    const [checkOutStatus, setCheckOutStatus] = useState(false);
    const [ivrAppoinment, setIvrAppoinment] = useState(false);
    const [ivrAppoinmentUid, setIvrAppoinmentUid] = useState([]);
    const [formDataIvr, setFormDataIvr] = useState({
      patient_name:"",
    });
    const [ivrPatientNameError, setIvrPatientNameError] = useState("");
    const [userType, setUserType] = useState("");
    const [formDataNew, setFormDataNew] = useState({ 
      consultation_type:"NORMAL",     
      appointment_date: new Date().toISOString(),
      appointment_type: "SINGLE",
      slot_uid: "",
      slot_duration: "",
      patient_name: "",
      mobile_number: "",
      smart_phone:true,      
      booking_source: "QR_WEB",
      visiting_status: "NEW",
      user_uid: doctor_uid
    });
    const [loaderopen, setloaderOpen] = React.useState(false);
    const [apiResData, setApiResData] = useState(true);
    const [slots, setSlots] = useState("");
    const [lineProgressLoading, setLineProgressLoading] = useState(false);
    const [getSlotDuration, setGetSlotDuration] = useState(false);

    const handleLoaderClose = () => {
      setloaderOpen(false);
    };
    const handleLoaderOpen = () => {
      setloaderOpen(true);
    };

    const fetchData = async() => {
      let queryParams = { "filter.doctor_uid": doctor_uid };
      let response = await Controller.ApiController(
        "GET",
        "/manage_users/"+doctor_uid,
        "",
        ""
      );
      // console.log(response,"responsiveDtata")
      if(response.type == "success"){      
      //  console.log(response,"full_namefull_name")
      let doctor = response.data.doctor
       localStorage.setItem("DoctorUid", doctor.doctor_uid);
       localStorage.setItem("user_type", response.data.user_type);
       
       setUserdata(doctor);
       setUserType(response.data.user_type)
      //  setEnterMobileNum(true)

      }else{
        console.log(response.error)
      }      
    }

    useEffect(()=>{
        fetchData();
      
      // console.log(userdata.data.hospital_or_clinic_name,"userDattalist")     
    }, []);

    useEffect(() => {
      const currentUrl = new URL(window.location.href);
      let duration = currentUrl.searchParams.get("duration");
      if(duration) {
        setFormDataNew((prevData) => ({
          ...prevData,
          slot_duration: duration
        }))
      } else {
        setGetSlotDuration(true);
      }
    }, []);

    
  const getSlotTime = async () => {
    let reqParams = {
        "filter.current_date":
          dayjs(new Date()).startOf("day").toISOString() || "",
      };
    try {
      setLineProgressLoading(true);
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${doctor_uid}`,
        "?",
        reqParams
      );
      setLineProgressLoading(false);
      
      if (res && res.data && Array.isArray(res.data)) {
        let slotTimeListdata = [];

          slotTimeListdata = res?.data;
        setSlots(slotTimeListdata || []);

      }else if(res.message){
        setLineProgressLoading(false);
        setSlots([]);
      }
    } catch (error) {
      // handleLoaderClose();
        setLineProgressLoading(false);
      console.log(error, "slotTimedata");
    }
  };

    const validateFields = () => {
      let patientNameValidate = false;
      let mobileValidate = false;
      let ivrPatientNameValidate = false;
     
      if(formDataNew.patient_name){
        // setEnterMobileNum(false)
      if (formDataNew.patient_name == "") {
        setPatientNameError(" ");
      } else if (formDataNew.patient_name) {
        let validInput = validationRegExp.fullName(formDataNew.patient_name);
        if (!validInput) {
          setPatientNameError("Invalid patient name");
        } else {
          setPatientNameError("");
          patientNameValidate = true;
        }
      }  }

      if(formDataIvr.patient_name){
        // setEnterMobileNum(false)
      if (formDataIvr.patient_name == "") {
        setIvrPatientNameError("");
      } else if (formDataIvr.patient_name) {
        let validInput = validationRegExp.fullName(formDataIvr.patient_name);
        if (!validInput) {
          setIvrPatientNameError("Invalid patient name");
        } else {
          setIvrPatientNameError("");
          ivrPatientNameValidate = true;
        }
      }  }
      
   if(formDataNew.mobile_number){
    // setYourName(false)
      if (formDataNew.mobile_number == "") {
        setMobileError(" ");
      } else if (formDataNew.mobile_number) {
        let validInputMob = validationRegExp.mobileNumberReg.test(
         formDataNew.mobile_number
        );
        if (validInputMob) {
          let mobile = "";
          let firstIndex =
            formDataNew.mobile_number[0] == undefined
              ? ""
              : formDataNew.mobile_number[0];
          var repeat = true;
          for (var i = 0; i < formDataNew.mobile_number.length; i++) {
            if (firstIndex != formDataNew.mobile_number[i]) {
              repeat = false;
            }
          }
          for (let i = 0; i < formDataNew.mobile_number.length; i++) {
            mobile +=
            formDataNew.mobile_number[i] != " " ? formDataNew.mobile_number[i] : "";
          }
          if (mobile.length > 9 || mobile == "") {
            if (repeat && mobile != "") {
              setMobileError("Invalid mobile number");
            } else {
              setMobileError("");
              mobileValidate = true;
            }
          } else {
            setMobileError("Mobile number must be 10 digit");
          }
        }
      }
    }

      return patientNameValidate || mobileValidate || ivrPatientNameValidate;
    };

    

    
    const handleSubmit = async (event) => {
      event.preventDefault();
      const validation = validateFields();      
      if(enterMobileNum || yourName ){
        if (!validation) {
          return;
          }
      }
      handleLoaderOpen();
      if(formDataNew.mobile_number !=""){      
      if(enterMobileNum){
      let datas = {...formDataNew};       
      // let res = { status: "NO", type: "success"  }    
      let res = await Controller.ApiController("PUT",`/qr_code/verifymobile/${datas.mobile_number}`,"",{user_uid : doctor_uid || ""})
      console.log(JSON.stringify(res),"dataresponsive")
      if(res){       
      if(res.type === "success"){
        handleLoaderClose();
        setVerifyPage(false)  
        setEnterMobileNum(false)     
        if(res.status == "INQUE"){
          setInQueData(true);
        }else if(res.status == "INVALID"){
          setInvalidStatus(true);
        }else if(res.status == "MISSED"){
          setMissedStatus(true);
        }else if(res.status == "CANCELLED"){
          setCancelledStatus(true);
        }else if(res.status == "ONDATE"){ 
          setOnDateStatus(true); 
          setOnDateShowMsg(UtilDateTime.formatYearMonthAndDayAsInt(res?.slot_start_time))
          setOnTimeShow(UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time))         
        }else if(res.status == "NEW_NUMBER"){
          setNewNumber(UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time));
          if(userType == "DOCTOR") {
            setFormDataNew({...formDataNew,slot_uid:res?.slot_uid});
          }
        }else if(res.status == "NO_APPOINTMENT"){
          setNoAppointments(true)          
        }else if(res.status == "USING_MOBILE_SLOT"){
          setUsingMobileSlot(true)
          const patientDataList = []
          const data = res.data;
          data.map((value)=>{
            const slotData = value;
            console.log(slotData,"slotData")
            const patientName = slotData.patient_name;
            const slotStartTime = slotData.from_time;           
            patientDataList.push({patientName:patientName,slotStartTime:slotStartTime});
          })
          console.log("MOBILE_SLOT :"+ JSON.stringify(patientDataList))
          setFilterDataList({appointment_date: res.appointment_date || "", data: patientDataList})    
        }else if(res.status == "IVR"){
          setIvrAppoinment(true)
          setIvrAppoinmentUid(res.appointment_uid)
        }else if(res.status == "ALREADY" && res.current_status == "IN_QUE"){
          setAlreadyInque(true)
        }else if((res.status == "ALREADY") && (res.current_status == "CHECK_IN")){
          setCheckInStatus(true);     
        }else if((res.status == "ALREADY") && (res.current_status == "CHECK_OUT")){
          setCheckOutStatus(true)
        }else{
          setErrorAccord(true)
        }       
      }else{
        handleLoaderClose();
        Alert("error", res.error.message);
        
      }
    }

  }else if (yourName && formDataNew.patient_name != ""){
    
      let data = { ...formDataNew };
      let res = await Controller.ApiController("POST", "/appointment","",data);     

      if (res) {
        if (res.type === "success") {
          handleLoaderClose();           
          setSuccessStatus("Your appointment is confirmed.");  
          setYourName(false)       
        } else {
          handleLoaderClose();
          // Alert("error", res.error.message);        
          setCancelStatus("Please contact the helpdesk.")
          setYourName(false)       
        }
        handleLoaderClose();
      }

    }else if(ivrAppoinment && formDataIvr.patient_name !=""){
      handleLoaderOpen() 
      let data = { ...formDataIvr };
      let resData = [];
      let successCount = 0;
      for (const uid of ivrAppoinmentUid) {
        let res = await Controller.ApiController(
          "PUT",
          `/appointment/${uid}`,"",
          data
        );
       if(!(res.type === "success")){        
        setApiResData(false)
       }else{
        successCount++;
       }
      }    
      handleLoaderOpen()     
       if(successCount == ivrAppoinmentUid.length)  { 
            handleLoaderOpen()           
         await ivrAppoinmentPatientName();
          setVerifyPage(false)  
          setEnterMobileNum(false)
          setIvrAppoinment(false)           
        } else {                     
          setCancelStatus("Please contact the helpdesk.")
          setIvrAppoinment(false)        
        }
       
        
      handleLoaderClose();      

    }  

  
}
    }


    const ivrAppoinmentPatientName = async () => {
      handleLoaderOpen();
      let datas = {...formDataNew}; 
      let res = await Controller.ApiController("PUT",`/qr_code/verifymobile/${datas.mobile_number}`,"",{user_uid : doctor_uid || ""})
      console.log(JSON.stringify(res),"dataresponsive")          
      if(res.type === "success"){
        handleLoaderClose();
        setVerifyPage(false)  
        setEnterMobileNum(false)     
        if(res.status == "INQUE"){
          setInQueData(true);
        }else if(res.status == "INVALID"){
          setInvalidStatus(true);
        }else if(res.status == "MISSED"){
          setMissedStatus(true);
        }else if(res.status == "CANCELLED"){
          setCancelledStatus(true);
        }else if(res.status == "ONDATE"){ 
          setOnDateStatus(true); 
          setOnDateShowMsg(UtilDateTime.formatYearMonthAndDayAsInt(res?.slot_start_time))    
          setOnTimeShow(UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time))             
        }else if(res.status == "NEW_NUMBER"){
          setNewNumber(UtilDateTime.formatTimeAsHHMMTT(res?.slot_start_time))
          if(userType == "DOCTOR") {
            setFormDataNew({...formDataNew,slot_uid:res?.slot_uid});
          }        
        }else if(res.status == "NO_APPOINTMENT"){
          setNoAppointments(true)          
        }else if(res.status == "USING_MOBILE_SLOT"){
          setUsingMobileSlot(true)
          const patientDataList = []
          const data = res.data;
          data.map((value)=>{
            const slotData = value;
            console.log(slotData,"slotData")
            const patientName = slotData.patient_name;
            const slotStartTime = slotData.from_time;          
            patientDataList.push({patientName:patientName,slotStartTime:slotStartTime});
          })
          console.log("MOBILE_SLOT :"+ JSON.stringify(patientDataList))
          setFilterDataList({appointment_date: res.appointment_date, data: patientDataList})          
        }else if(res.status == "IVR"){
          setIvrAppoinment(true)
          setIvrAppoinmentUid(res.appointment_uid)
        }else if(res.status == "ALREADY" && res.current_status == "IN_QUE"){
          setAlreadyInque(true)
        }else if((res.status == "ALREADY") && (res.current_status == "CHECK_IN")){
          setCheckInStatus(true);     
        }else if((res.status == "ALREADY") && (res.current_status == "CHECK_OUT")){
          setCheckOutStatus(true)
        }else{
          setErrorAccord(true)
        }        
      }else{
        handleLoaderClose();
        Alert("error", res.error.message);        
      }     
      
    }

    const navigatNamePage = () => {
      if(newNumber){
        setYourName(true)
        setNewNumber(false)   

      } 
    }

    

    // const mobileOnChange = (e) => {
    //   setFormData({
    //     ...formData,mobile_number:e.target.value,
    //   });
    // }

    

    const patientNameOnChange = (e) => {
      if (validationRegExp.fullName(e.target.value)) {
        setFormDataNew({
          ...formDataNew,patient_name:e.target.value,
        });
        if (patientNameError) {
          setPatientNameError("");
        }
      };
     
    }

    const ivrPatientNameOnChange = (e) => {
      if (validationRegExp.fullName(e.target.value)) {
        setFormDataIvr({
          ...formDataIvr,patient_name:e.target.value,
        });
        if (ivrPatientNameError) {
          setIvrPatientNameError("");
        }
      };
     
    }

    const mobileNumberOnChange = (e) => {
      if (validationRegExp.mobileNumberReg.test(e.target.value)) {
        setFormDataNew({
          ...formDataNew,mobile_number:e.target.value,
        });
        if (mobileError) {
          setMobileError("");
        }
      }
      
    }

    const closeTheCurrentPage = () => {
      setEnterMobileNum(true)
      setFormDataNew({
        ...formDataNew,mobile_number:"",patient_name: ""
      });
      setFormDataIvr({
        ...formDataIvr,patient_name:"",
      });
      setSuccessStatus(false)
      setMissedStatus(false)
      setInvalidStatus(false)
      setCancelledStatus(false)
      setInQueData(false)
      setOnDateStatus(false)
      setNoAppointments(false)
      setUsingMobileSlot(false)
      setThankYouPage(false)
      setCancelStatus(false)
      setAlreadyInque(false)
      setCheckInStatus(false)
      setCheckOutStatus(false)
      setErrorAccord(false)
      setIvrAppoinment(false)
      
      
    }

   const closeTheCancelButton = () => {
    setEnterMobileNum(true)
    setFormDataNew({
      ...formDataNew,mobile_number:"",
    });
    setFormDataIvr({
      ...formDataIvr,patient_name:"",
    });
    setNewNumber(false)
    setYourName(false)
    setIvrAppoinment(false)
    setVerifyPage(false)
    

   }  

   const closeTheNoButton = () => {
    setThankYouPage(true)
    setFormDataNew({
      ...formDataNew,mobile_number:"",
    });
    setFormDataIvr({
      ...formDataIvr,patient_name:"",
    });
    setNewNumber(false)
    setYourName(false)
    setIvrAppoinment(false)
    setVerifyPage(false)
    

   }  

  
  

  return (
   <>
     
      <Grid container>
      <Grid item xs={12} mt={3} >
        <Box sx={{display:"flex",justifyContent:"center"}}>
      <img src={LogoImae} alt='logoicon' />
      </Box>
      </Grid>
      <Grid item xs={12} mt={8}>
        <Box  component="form" onSubmit={handleSubmit} sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              width: { xs: "95%", sm: "80%", md: "600px" },
              paddingBottom: "20px",
              //height: verifyPage ? "37vh" : onDateStatus ? "37vh" : inQueData ? "37vh" :invalidStatus ? "37vh" :missedStatus ? "40vh" :cancelledStatus ? "37vh" :noAppointments ? "40vh" : successStatus ? "33vh" :cancelStatus ? "33vh" : usingMobileSlot ? "60vh"  :thankYouPage ? "37vh" :enterMobileNum ? {xs:"55vh",sm:"55vh",md:"50vh"} :yourName ? "50vh" : ivrAppoinment ? "50vh" : newNumber ? "37vh" : alreadyInque ? "33vh" : errorAccord ? "33vh" : checkInStatus ? "33vh" : checkOutStatus ? "33vh" : "45vh",
              borderRadius: "10px 10px 10px 10px",
            }}
          >
            <Box
              sx={{
                height: "60px",
                backgroundColor:inQueData ? "#FFD8B4" : invalidStatus ? "#FFD7D7" : missedStatus ? "#FFD7D7" :cancelledStatus ? "#FFD7D7" :noAppointments ? "#FFD7D7" :thankYouPage ? "#FFD7D7" : errorAccord ? "#FFD7D7" : "#C2FFEA",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <IconButton
                sx={{
                  position: "relative",
                  top: "30px",
                  left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
                  transform: "translateX(-50%)", // Center the button horizontally
                  backgroundColor:invalidStatus ? "#BC123E" : missedStatus ? "#BC123E" : cancelledStatus ? "#BC123E" : errorAccord ? "#BC123E" : "#41BA8F",
                  height: "54px",
                  width: "55px",
                  "&:hover": { backgroundColor:inQueData ? "#EC7A11"  :invalidStatus ? "#BC123E" : missedStatus ? "#BC123E" :cancelledStatus ? "#BC123E" :errorAccord ? "#BC123E" : "#41BA8F" },
                  cursor: "default",
                }}
              >
               {verifyPage ? <img src={ScanImage} alt='scanicon' /> : inQueData ?  <img src={InqueImg} alt="inqueimg" /> : invalidStatus ? <img src={InvalidImg} alt='invaliedImg' /> : missedStatus ? <img src={MissedImg} alt='missedImg' /> : cancelledStatus ? <img src={CancelledImg} alt='canceledImg' /> : onDateStatus ? <img src={OnDateImg} alt='ondateimg' />  : newNumber ? <img src={OnDateImg} alt='ondateimg' /> : noAppointments ? <img src={NoAppointmentsImg} alt='scanicon' /> :cancelStatus ? <img src={CancelImg} alt='cancel' /> : usingMobileSlot ? <img src={OnDateImg} alt='slot'/> : thankYouPage ? <img src={ThankYouImg} alt='ThankYouImg' /> : errorAccord ? <PriorityHighIcon sx={{color:"white"}}/> : <img src={ScanImageVerify} alt='scaniconVerify' /> }
              </IconButton>
            </Box>
            <Box sx={{
              minHeight: "150px", 
              alignContent: "center"
              }}
            >
              <Box sx={{ marginTop: "40px",display:"flex",justifyContent:"center"}}>              
                <Box >
                {enterMobileNum && <>  <Typography sx={{ color: "#231F20",fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center" }}>
                {userdata?.data?.hospital_or_clinic_name}          
                </Typography> 
                <Box sx={{display:"flex",justifyContent:"center"}}>
                <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "#000000", marginTop: "15px",marginRight:"8px"}}>
                Enter your mobile number
                </Typography> 
                </Box></>}
                {yourName && <>  <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center"  }}>
                {userdata?.data?.hospital_or_clinic_name}              
                </Typography>
                <Box sx={{display:"flex",justifyContent:"center"}}>
                <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "#000000", marginTop: "15px",marginRight:"17px"}}>
                Enter the Patient Name
                </Typography>
                </Box>  </>}
                {ivrAppoinment && <>  <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center"  }}>
                {userdata?.data?.hospital_or_clinic_name}              
                </Typography>
                <Box sx={{display:"flex",justifyContent:"center"}}>
                <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "#000000", marginTop: "15px",marginRight:"17px"}}>
                  Enter the Patient Name
                </Typography>
                </Box>  </>}
                {verifyPage && <> <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Verify
                </Typography> <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
                  Appointment 
                </Typography></>}
                {inQueData && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Thank you for arriving.
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
                You are placed in que.
                </Typography> </> }
                {invalidStatus && <> <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Your appointment
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
                is invalid.
                </Typography></>}
                {missedStatus && <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                You missed your fixed 
                </Typography>}
                {missedStatus && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
                appointment time.
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
                Please contact the helpdesk.
                </Typography></>}
                {cancelledStatus && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Your appointment is cancelled.
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
                Please check with the Helpdesk.
                </Typography> </> }
                {onDateStatus && <> <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",justifyContent:"center",textAlign:"center"  }}>
                Your appointment is on
                </Typography>
                <Typography sx={{ color: "#41BA8F", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",justifyContent:"center",textAlign:"center"  }}>
                {onDateShowMsg}<Typography sx={{fontWeight: 600, fontSize: "15px",color:"#231F20"}}>&nbsp;at&nbsp;</Typography>{onTimeShow}
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",justifyContent:"center",textAlign:"center"  }}>
                Please check with the helpdesk.
                </Typography>
                </>}
                {newNumber && <> 
                  <Box style={{ textAlign: "center" }}>
                <Typography sx={{  color: "#231F20", fontWeight: 600, fontSize: "15px", lineHeight: "25px"}}>
                Appointment available at<br/>
                <span style={{ color: "#41BA8F" }}>{newNumber}</span>.&nbsp;Do you want to confirm?<br/>
                {userType == "SCAN" && getSlotDuration && "Please select the slot duration"}
                
                </Typography>
                {userType == "SCAN" && getSlotDuration && 
                  
                <BasicSelect
                  value={formDataNew.slot_duration || "default"}
                  onChange={(e) => setFormDataNew( (prevData) => ({
                    ...prevData,
                    slot_duration: e.target.value 
                  }))}
                  sx={{ textAlign: "left", width: "220px", marginTop: "10px" }}
                  onOpen={getSlotTime}
                >
                  <BasicMenuItem value="default" style={{ display: "none" }}>
                    <span style={{ color: "#bbb7b7" }}>Select</span>
                  </BasicMenuItem>
                  { lineProgressLoading ? 
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box> : 
                      Array.isArray(slots) && slots.length > 0 ? (
                        slots.map((val, i) => (
                          <BasicMenuItem key={i} value={val}>
                            {val + " Minutes"}
                          </BasicMenuItem>
                        ))
                      ) : (
                        <BasicMenuItem>No doctors available</BasicMenuItem>
                      )
                  }
                </BasicSelect>}
                </Box>
                </>}
                {noAppointments && <> <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center"}}>
                No appointments are           
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center"}}>
                available for today.          
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center"}}>
                Please contact the helpdesk       
                </Typography>
                </>}
                {successStatus && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                {successStatus ? <span style={{textAlign: "center"}}>
                  Your appointment is confirmed. <br /> Your appointment details will be shared through SMS or WhatsApp.
                </span> : ""}
                </Typography></>}
                {cancelStatus && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                {cancelStatus}             
                </Typography></>}
                {usingMobileSlot && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center" }}>
                Appointments booked using mobile {formDataNew.mobile_number}
                </Typography>
                <Typography sx={{fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center" }}>
                on&nbsp;<Typography sx={{ color: "#41BA8F", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center",textAlign:"center" }}>{filterDataList?.appointment_date ? UtilDateTime.formatYearMonthAndDayAsInt(filterDataList?.appointment_date) : "--"}</Typography>
                </Typography>
                
                <Box sx={{height:"70px",overflowY:"scroll",width:"100%",mt:1,}}>
                {filterDataList?.data?.map((patient, index) => (<>
                <Box key={index} sx={{display:"flex",justifyContent:"space-evenly"}}>
                  <Typography  sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                {patient.patientName} at  <Typography sx={{color:"#41BA8F",marginLeft:"6px"}}>{UtilDateTime.formatTimeAsHHMMTT(patient.slotStartTime)}</Typography>
                  </Typography>
                
                  </Box>               
                  
              </>
              ))}
              </Box>
              <Typography  sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" ,mt:2,textAlign:"center" }}>
                  Please contact the helpdesk.
                  </Typography>
                </>}

                {thankYouPage && <><Typography sx={{ color: "#231F20",marginTop:"6px", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                You do not have an appointment.            
                </Typography>
                <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Thank you!            
                </Typography>
                </>}

                {alreadyInque && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Already In Que    
                </Typography></>}

                {errorAccord && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Error Occured    
                </Typography></>}

                {checkInStatus && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Already Checked In         
                </Typography></>}

                {checkOutStatus && <><Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
                Already checked out         
                </Typography></>}
              
                {enterMobileNum &&   (<Box sx={{marginTop:"13px",display:"flex",justifyContent:"center"}} >
                  <Box>
                  <input
                    id=""
                    variant="outlined"
                    placeholder= "ex: 99999 99999"
                    size="small"                   
                    name="mobile_number"
                    value={formDataNew.mobile_number}
                    onChange={(e) => mobileNumberOnChange(e)}
                    style={{
                      paddingLeft:"4px",
                      width: "170px", // Make the input full width
                      height: "40px",
                      fontSize: "14px", 
                      border:mobileError ?  "1px solid red" : "1px solid #C8C7C7",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                  },
                                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      border: 0,
                                    },             
                      
                    }}
                  />
                  <Typography className="errorValidationTextMobileQr">
                            {mobileError}
                          </Typography>
                  </Box>
                
                  
                </Box>
                )}
                {yourName &&   (<Box sx={{marginTop:"13px",display:"flex",justifyContent:"center"}} >
                  <TextField
                    id=""
                    variant="outlined"
                    placeholder="ex: John Doe"
                    size="small"
                    error={patientNameError ? patientNameError : ""}
                    name="patient_name"
                    value={formDataNew.patient_name}
                    onChange={(e) => patientNameOnChange(e)}
                    sx={{
                      width: "170px",                   
                      height: "40px",
                      fontSize: "14px !important",
                      "& .MuiOutlinedInput-root": {
                        // '&:hover fieldset': {
                        //   borderColor: 'green', // border color on hover
                        // },
                        
                        "&.Mui-focused fieldset": {
                          borderColor: "#C8C7C7", // border color on focus
                        },
                      },
                    }}
                  />
                  <Typography className="errorValidationTextMobileQr">
                            {patientNameError}
                          </Typography>
                </Box> )}
                {ivrAppoinment &&   (<Box sx={{marginTop:"13px",display:"flex",justifyContent:"center"}} >
                  <TextField
                    id=""
                    variant="outlined"
                    placeholder="ex: John Doe"
                    size="small"
                    error={ivrPatientNameError ? ivrPatientNameError : ""}
                    name="patient_name"
                    value={formDataIvr.patient_name}
                    onChange={(e) => ivrPatientNameOnChange(e)}
                    sx={{
                      width: "170px",                   
                      height: "40px",
                      fontSize: "14px !important",
                      "& .MuiOutlinedInput-root": {
                        // '&:hover fieldset': {
                        //   borderColor: 'green', // border color on hover
                        // },
                        
                        "&.Mui-focused fieldset": {
                          borderColor: "#C8C7C7", // border color on focus
                        },
                      },
                    }}
                  />
                  <Typography className="errorValidationTextMobileQr">
                            {ivrPatientNameError}
                          </Typography>
                </Box> )}
                </Box>
            
              </Box>
              
              <Box sx={{ marginTop:"10px" }}>
              
            
          {inQueData || invalidStatus || missedStatus || cancelledStatus || onDateStatus || noAppointments || successStatus || cancelStatus || usingMobileSlot || thankYouPage || alreadyInque || errorAccord || checkInStatus || checkOutStatus ?  (<Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: "10px",
                  }}
                >
                  {/* !successStatus */}
                  {false &&<Button
                    sx={{
                      mt:onDateStatus ? 1 : 3,
                      mb: 2,
                      mr:1,
                      backgroundColor: "#1A71FF",
                      color: "#FFFFFF",
                      fontSize: "13px",
                      border: "1px solid #FFF",
                      borderRadius: "50px",
                      height: "30px",
                      "&:hover": { background: "#1A71FF" },
                      width: "100px",
                      fontWeight: 600,
                    }}
                    onClick={closeTheCurrentPage}
                  >
                    Ok
                  </Button>}
                  
                </Box>) :
                <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
  {newNumber ?
  <Button
      sx={{
        mt: 2,
        mb: 2,
        mr:1,
        backgroundColor: "#D13C3C",
        color: "#FFFFFF",
        fontSize: "13px",
        border: "1px solid #FFF",
        borderRadius: "50px",
        height: "30px",
        "&:hover": { background: "#D13C3C" },
        width: "100px",
        fontWeight: 600,
      }}
      onClick={closeTheNoButton}
    >
    No
    </Button> : !enterMobileNum ?
                <Button
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    backgroundColor: "#D13C3C",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    border: "1px solid #FFF",
                    borderRadius: "50px",
                    height: "30px",
                    "&:hover": { background: "#D13C3C" },
                    width: "100px",
                    fontWeight: 600,
                  }}
                  onClick={closeTheCancelButton}
                >
                  Cancel
                </Button> : "" }
                <Button
                  type={enterMobileNum  ? "submit" : yourName || ivrAppoinment ? "submit" :  "button"}
                  disabled={((enterMobileNum && formDataNew.mobile_number.length == 0) || (yourName && formDataNew.patient_name.length == 0) || (newNumber && userType == "SCAN" && !formDataNew.slot_duration) || (ivrAppoinment && formDataIvr.patient_name.length == 0) ) }
                  sx={{
                    mt:newNumber ? 2 : enterMobileNum ? 2 : 3,
                    mb: 2,
                    backgroundColor: "#41BA8F",
                    color: "#FFFFFF",
                    border: "1px solid #FFF",
                    fontSize: "13px",
                    borderRadius: "50px",
                    height: "30px",
                    "&:hover": { background: "#41BA8F" },
                    width: "100px",
                    fontWeight: 600,
                  }}
                  onClick={navigatNamePage}
                >
                  {enterMobileNum ? "Submit" :newNumber ? "Yes" :yourName || ivrAppoinment ? "Submit" : "" }
                </Button>
              </Box>}
              
              </Box>
            </Box>
          </Card>
        </Box>
      </Grid>
      <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
    </Grid>
      </>
  );
}
