import DashboardIcon from "../Assets/navbar/dashbordIconNew.svg";
import Appointment from "../Assets/navbar/doctor-appointment.svg";
import Users from "../Assets/navbar/Manage_user.svg";
import Logout from "../Assets/navbar/logout.svg";
import Setting from "../Assets/navbar/Setting.svg"
import QRGenerate from "../Assets/navbar/qrGenerate.svg"
import dashbordHideIcon from "../Assets/icons/dashbordHideImage.svg"
import appointmentHideIcon from "../Assets/icons/appointmentHideImage.svg"
import settingHideIcon from "../Assets/icons/settingHideImage.svg"
import manageUserHideIcon from "../Assets/icons/manageuserHideImage.svg"
import qrGenImage from "../Assets/icons/qr-code.svg"

import DashboardActive from "../Assets/mobileNavbar/dashboard_active.svg";
import AppointmentActive from "../Assets/mobileNavbar/appointments_active.svg";
import ManageUserActive from "../Assets/mobileNavbar/user_active.svg";
import SettingsActive from "../Assets/mobileNavbar/settings_active.svg";
import DashboardBlack from "../Assets/mobileNavbar/dashboard_black.svg"
import qr_code from"../Assets/mobileNavbar/qr_code.svg"
import qr_active from "../Assets/mobileNavbar/qr_active.svg"
import reportsNavIcon from "../../src/Assets/icons/reportsNavIcon.svg"







const MobileNavItems = [
    // {
    //   name: "Dashboard",
    //   icon:DashboardIcon,  
    //   fadeIcon:dashbordHideIcon,    
    //   url : "/dashboard",
    //   key: "DASHBOARD",
    //   activePage:DashboardActive,
    // },

    {
      name: "Manage Appointments",
      icon:Appointment,
      fadeIcon:appointmentHideIcon,
      url : "/manageappointments",
      key: "MANAGE_APPOINTMENTS",
      activePage:AppointmentActive,
      for: ["ALL_USER"]      
      
    },
    {
        name: "QR Code",
        icon: qr_code,
        fadeIcon:qrGenImage,
        url : "/generateQR",
        key: "QR_GENERATE",
        activePage:qr_active,
        for: ["ALL_USER"]      
      },
    {
      name: "Manage users",
      icon:Users,
      fadeIcon:manageUserHideIcon,
      url : "/manageusers",
      key: "MANAGE_USERS",
      activeUrl:["/manageusers", "/manageusers/addusers", "/manageusers/Editusers"],
      activePage:ManageUserActive,
      for: ["ALL_USER"]      
      
    },
    {
      name: "Settings",
      icon:Setting,
      fadeIcon:settingHideIcon,
      url : "/all-settings",
      activeUrl:["/settings"],
      activePage:SettingsActive,
      key: "SETTINGS",
      for: [ "EXCEPT_USER", "EXCEPT_DOCTOR", "EXCEPT_SCAN"]
    },
    {
      name: "Settings",
      icon:Setting,
      fadeIcon:settingHideIcon,
      url : "/settings/:user_uid",
      activeUrl:["/settings"],
      activePage:SettingsActive,
      key: "SETTINGS",
      for: ["USER", "DOCTOR", "SCAN"]
    },
    {
      name: "Reports",
      icon:reportsNavIcon,
      fadeIcon:reportsNavIcon,
      url : "/reports",
      key: "REPORTS",
      activePage:reportsNavIcon,
      for: ["ALL_USER"]      
    },
    
  ];

  export default MobileNavItems