import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:{ 
        admin_uid:"", 
        admin_name:"",
        user_uid:"", 
        user_full_name:"", 
        user_type:"", 
        hospital_or_clinic_name:"",
        maped_doctor_uid: "",
        maped_doctor_name: "",
        update_render: 0,
        filter: false
    }
}

export const userDetails = createSlice({
    name:"userDetails",
    initialState,
    reducers:{
        updateUserData:(state, action) =>{
            state.data = action.payload
            console.log(state.data);
        }
    }
})

export const { updateUserData } = userDetails.actions;
export default userDetails.reducer