import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const currentDate = dayjs();
let filterFrom = currentDate.startOf("date");
let filterTo = currentDate.endOf("date");

const initialState = {
    data:{ 
        date: "", 
        from_date: "", 
        to_date: "",
        user_uid: [],
        maped_user_uid: [],
        update_render: 0,
        filter: false
    }
}

export const filterData = createSlice({
    name:"filterData",
    initialState,
    reducers:{
        updateFilterData:(state, action) =>{
            state.data = action.payload
            console.log(state.data);
        }
    }
})

export const { updateFilterData } = filterData.actions;
export default filterData.reducer