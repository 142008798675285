import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from ".././Assets/icons/addUserTick.svg";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import Controller from "../Controller/ApiController";
import { object } from "prop-types";
import ValueFormat from "../Service/valueFormate";
import CustomizedButtons from "../Components/ReuseComponents/Buttons/ColorButton";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import Loader from "../Components/ReuseComponents/Loader/loader";
import { useSelector } from "react-redux";
import UtilDateTime from "../Constant/utillDateTime";
import {
  BasicMenuItem,
  BasicSelect,
  MultipleSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import { useDispatch } from "react-redux";
import { bulkMoveRefresh } from "../Components/Assets/Redux/features/Appointment/bulkMoveRefresh";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

export default function BulkMove({ open, onClose, fetchGetAPIData }) {
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const minDate = dayjs(currentDate);
  const minTime = dayjs(currentDate);
  const storedDate = useSelector((state) => state.dateFilter.date);
  const [value, setValue] = React.useState(dayjs(storedDate));
  const [appointmentSuccessData, setAppointmentSuccessData] = useState({
    moved: 0,
    unmoved: 0
  });
  const [time, setTime] = useState({
    fromTime: dayjs(currentDate),
    toTime: dayjs(currentDate),
    update: "",
  });
  const [successModal, setSuccessModal] = useState(false);
  const [fromTimeError, setFromTimeError] = useState(false);
  const [cancelBulkModal, setCancelBulkModal] = useState(false);
  const [submitValidation, setSubmitValidation] = useState(true);
  const [cancelData, setcancelData] = useState({
    queCount: 0,
    notarrivedCount: 0,
    totalCount: 0,
  });
  const [uidData, setUIDData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [resonMessage, setResonMessage] = useState("");
  const [loaderOpen, setLoaderOpenn] = useState(false);
  const [popupType, setPopUpType] = useState("");
  const docterUid = localStorage.getItem("DoctorUid");
  const [users, setUsers] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [formData, setFormData] = useState({
    user_uid: {
      value: "",
      error: false,
    },
  });
  const dispatch = useDispatch();
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const handlechange = (e) => {
    const { value, name } = e.target;
    let filerValue = typeof value === "string" ? value.split(",") : value;
    filerValue = filerValue.filter((val) => val != "Select");
    setSelectedDoctors(filerValue);
  };

  const manageApiRef = useSelector(
    (state) => state.manageAppointmentSlice.appointment
  );
  const filteredUsersForToDoctor = users.filter(
    (user) => !selectedDoctors.includes(user) // Exclude doctors selected in "From Doctor"
  );

  const handleSuccessOpenModal = () => {
    setSuccessModal(true);
  };

  const handleSuccessCloseModal = () => {
    setSuccessModal(false);
    onClose();
    setAppointmentSuccessData({
      moved: 0,
      unmoved: 0
    });
  };

  const handleChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  useEffect(() => {
    let currentdate = new Date();
    currentdate = currentdate.setHours(0, 0, 0, 0);

    if (new Date(currentdate).toISOString() == storedDate) {
      currentdate = new Date().toISOString();
      setCurrentDate(dayjs(storedDate).startOf("minute"));
    } else {
      currentdate = storedDate;
      setCurrentDate(dayjs(storedDate).startOf("minute"));
    }
    console.log(
      dayjs(storedDate).startOf("minute").toISOString(),
      dayjs(storedDate).toISOString(),
      "uihiuhuiiuhihiiu"
    );
  }, [storedDate]);

  useEffect(() => {
    console.log(
      time.fromTime !== "",
      !time.fromTime.isBefore(currentDate) ,
      uidData.length > 0 ,
      selectedDoctor,
      value !== "", "valuenbiuuibvalue", selectedDoctor
    )
    if (value !== "") {
      if (
        time.fromTime !== "" &&
        !time.fromTime.isBefore(currentDate) &&
        uidData.length > 0 &&
        selectedDoctor
      ) {
        setSubmitValidation(false);
      } else {
        setSubmitValidation(true);
      }
    }
  }, [value, time, currentDate, uidData, selectedDoctor]);

  const userRequest = async () => {
    try {
      const queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": docterUid,
      };

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      console.log(response, "responsemonresponse1");
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2");
        setUsers(response?.data || []);
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    userRequest();
  }, []);

  const getCancleAppointment = async () => {
    setLoaderOpenn(true);
    let uidDataTemp = [];
    const requestParams = {
      "filter.slot_start_time": dayjs(storedDate)
        .set("hours", time.fromTime.$H)
        .set("minutes", time.fromTime.$m)
        .set("seconds", 0)
        .set("millisecond", 0)
        .toISOString(),
      "filter.slot_end_time": dayjs(storedDate).endOf('day').toISOString(),
        // .set("hours", time.toTime.$H)
        // .set("minutes", time.toTime.$m)
        // .set("seconds", 0)
        // .set("millisecond", 0)
        // .toISOString(),
      // "filter.slot_status": "NOT_ARRIVED",
      "filter.doctor_uid": docterUid,
      "filter.paginate": "NO",
      "filter.appointment_date": storedDate || "",
      "filter.user_uid": selectedDoctors.map((val) => val.user_uid).join(","),
    };
    console.log();
    const result = await Controller.ApiController(
      "GET",
      "/appointment",
      "?",
      requestParams
    );
    console.log("getCancleAppointment:", result);

    const resultData = ValueFormat.parseAsArray(result.data);
    const queCount = ValueFormat.parseAsInt(result.in_que_count);
    const notArrived = ValueFormat.parseAsInt(result.not_arrived_count);
    const totalRecords = ValueFormat.parseAsInt(result.total_records);
    resultData.map((value) => {
      const appointment_uid = ValueFormat.parseAsString(value.appointment_uid);
      uidDataTemp.push(appointment_uid);
    });
    setUIDData(uidDataTemp);
    setcancelData((prev) => {
      return {
        ...prev,
        queCount: queCount,
        notarrivedCount: notArrived,
        totalCount: totalRecords,
      };
    });
    setLoaderOpenn(false);
  };

  // console.log(selectedDoctor, "sdfsdfddfdfg");

  const submitCancel = async () => {
    if (!selectedDoctor.user_uid) {
      Alert("error", "Please select a doctor before proceeding.");
      return;
    }

    const uidDataList = {
      user_uid: selectedDoctor.user_uid,
      appointment_uids: uidData,
      reason: resonMessage,
    };

    console.log(uidDataList, "sdfsdfddfdfg");
    const result = await Controller.ApiController(
      "POST",
      "/move_appointment",
      "",
      uidDataList
    );
    const status = result.type;
    if (status === "success") {
      Alert("success", "Appointments moved successfully!");
      manageApiRef();
      // cancelModelclose();
      handleSuccessOpenModal();
      setCancelBulkModal(false);
      setAppointmentSuccessData({
        moved: result?.appointment_moved || 0,
        unmoved: result?.appointment_unmoved || 0
      })
      // setTimeout(() => {
      //   fetchGetAPIData();
      // }, 500);
      // getCancleAppointment();
    }
    console.log("result1: ", result);
  };

  const fetchData = () => {
    // let addTwoTime = dayjs(time.fromTime).add(10, "minutes");
    // if (addTwoTime <= time.toTime) {
    getCancleAppointment();
    // } else {
    setFromTimeError(true);

    // }
  };

  useEffect(() => {
    if (
      (time.fromTime.isSame(dayjs(currentDate)) ||
        time.fromTime.isAfter(dayjs(currentDate))) &&
      selectedDoctors.length > 0
    ) {
      setButtonDisabled(false);
    } else setButtonDisabled(true);

  }, [time.fromTime, selectedDoctors]);

  const cancelModel = (type) => {
    setPopUpType(type);
    setCancelBulkModal(true);
  };

  const cancelModelclose = () => {
    setTime({
      fromTime: dayjs(currentDate),
      toTime: dayjs(currentDate),
    });
    setcancelData({
      queCount: 0,
      notarrivedCount: 0,
      totalCount: 0,
    });
    setFromTimeError(false);
    setCancelBulkModal(false);
    setSelectedDoctors("");
    setSelectedDoctor("");
    setResonMessage("");
    handleSuccessCloseModal();
  };

  const cancelModelcloseChild = () => {
    setCancelBulkModal(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={onClose}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            sx={{
              maxWidth: {
                xl: "50%",
                lg: "60%",
                md: "80%",
                sm: "590px",
                xs: "310px",
              },
              borderRadius: "10px",
            }}
          >
            <Loader loaderOpen={loaderOpen} />

            <Grid sx={{ borderRadius: "10px" }} container>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
              >
                <Box sx={{ padding: { sm: "10px 54px", xs: "20px 34px" } }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: { md: "-30px", xs: "-15px", sm: "-35px" },
                    }}
                  >
                    Bulk Move - {UtilDateTime.formatMonthDateYear(storedDate)}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                sx={{ padding: { md: "10px 25px", xs: "15px 20px" } }}
              >
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={5}
                  xs={12}
                  sm={12}
                  sx={{
                    borderRight: { md: "1px solid #000000", xs: "none" },
                    paddingRight: "10px",
                  }}
                >
                  <Box fullWidth sx={{ display: { md: "none" } }}>
                    <Grid container>
                      <Grid
                        item
                        xs={5.5}
                        sm={3}
                        sx={{ alignContent: "center" }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                            width: {
                              lg: "150px",
                              md: "160px",
                              whiteSpace: "nowrap",
                            },
                            height: "30px",
                            alignContent: "center",
                          }}
                        >
                          Appointment For
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} sm={9}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            sx={{
                              "& .MuiInputBase-root": {
                                width: { xs: "160px", sm: "180px" }, // Adjust the width as needed
                                height: "30px", // Adjust the width as needed
                                overflow: "hidden",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                overflow: "hidden",
                              },
                              "& .MuiSvgIcon-root": {
                                width: "0px", // Adjust icon size if necessary
                                height: "0px", // Adjust icon size if necessary
                              },
                            }}
                            readOnly
                            format="DD/MM/YYYY"
                            minDate={minDate}
                            slotProps={{ textField: { size: "small" } }}
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            components={{
                              OpenPickerIcon: () => null, // Render an empty component to hide the default icon
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  "& .MuiInputBase-input": {
                                    fontSize: "13px", // Adjust the font size as needed
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid></Grid>
                    </Grid>
                  </Box>

                  <Box fullWidth mt={{ xs: 2, md: 1 }}>
                    <Grid container>
                      <Grid item xs={5.5} sm={3}>
                        {" "}
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#000000",
                            height: "30px",
                            alignContent: "center",
                          }}
                        >
                          From Doctor
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} sm={9}>
                        <MultipleSelect
                          sx={{ width: { xs: "160px", sm: "180px" } }}
                          value={
                            selectedDoctors.length > 0
                              ? selectedDoctors
                              : ["Select"]
                          }
                          onChange={handlechange}
                          typeOfValue={selectedDoctors.length > 0 && "OBJECT"}
                          typeOfKey="full_name"
                        >
                          <BasicMenuItem
                            value="Select"
                            style={{ display: "none" }}
                          >
                            Select
                          </BasicMenuItem>
                          {Array.isArray(users) && users.length > 0 ? (
                            users.map((val, i) => (
                              <BasicMenuItem key={i} value={val}>
                                <ListItemText
                                  primary={val.full_name}
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: "13px",
                                    },
                                  }}
                                />
                                <Checkbox
                                  sx={{ padding: 0, marginLeft: "10px" }}
                                  checked={selectedDoctors.includes(val)}
                                  size="small"
                                />
                              </BasicMenuItem>
                            ))
                          ) : (
                            <BasicMenuItem>No doctors available</BasicMenuItem>
                          )}
                        </MultipleSelect>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box fullWidth mt={2}>
                    <Grid container>
                      <Grid item xs={5.5} sm={3}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#000000",
                            height: "30px",
                            alignContent: "center",
                          }}
                        >
                          From
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} sm={9}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopTimePicker
                            sx={{
                              "& .MuiInputBase-root": {
                                width: { xs: "160px", sm: "180px" }, // Adjust the width as needed
                                height: "30px", // Adjust the width as needed
                                overflow: "hidden",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                padding: "5px 0px 4px 10px",
                              },
                              "& .MuiSvgIcon-root": {
                                width: "16px", // Adjust icon size if necessary
                                height: "16px", // Adjust icon size if necessary
                              },
                            }}
                            // minTime={minTime}
                            slotProps={{
                              textField: { size: "small" },
                            }}
                            value={time.fromTime}
                            onChange={(newValue) => {
                              setTime((prev) => ({
                                ...prev,
                                fromTime: newValue,
                                update: newValue,
                              }));
                              setFromTimeError(false);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* <Box fullWidth mt={2}>
                      <Grid container>
                        <Grid item xs={5.5} sm={3}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#000000",
                              height: "30px",
                              alignContent: "center",
                            }}
                          >
                            To
                          </Typography>
                        </Grid>
                        <Grid item xs={6.5} sm={9}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                              sx={{
                                "& .MuiInputBase-root": {
                                  width: {xs: "160px", sm: "180px"}, // Adjust the width as needed
                                  height: "30px", // Adjust the width as needed
                                  overflow: "hidden",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "13px",
                                  padding: "5px 0px 4px 10px",
                                },
                                "& .MuiSvgIcon-root": {
                                  width: "16px", // Adjust icon size if necessary
                                  height: "16px", // Adjust icon size if necessary
                                },
                              }}
                              minTime={minTime}
                              slotProps={{
                                textField: { size: "small" },
                              }}
                              value={time.toTime}
                              onOpen={() => setFromTimeError(false)}
                              onChange={(newValue) => {
                                setTime((prev) => ({
                                  ...prev,
                                  toTime: newValue,
                                  update: newValue,
                                }));
                                setFromTimeError(false);
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Box> */}
                  {/* <Box>
                      {fromTimeError && (
                        <Typography className="errorValidationFromTime">
                          To time should be greater
                          <br /> than from time
                          {window.innerWidth < 900 ? <br /> : ""}
                          &nbsp;(Minimum 10 mins)
                        </Typography>
                      )}
                    </Box> */}

                  <Box mt={4}>
                    <CustomizedButtons
                      onClick={fetchData}
                      disabled={buttonDisabled}
                      bgColor={"#2C73EB"}
                      color={"white"}
                      hoverColor={"#2C73EB"}
                      text={"Fetch Appointments"}
                      style={{
                        fontSize: "14px",
                        borderRadius: "50px",
                        paddingLeft: 3,
                        paddingRight: 3,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={7}
                  lg={7}
                  md={7}
                  xs={12}
                  sm={12}
                  sx={{
                    padding: { md: "0px 0px 0px 30px", xs: "0px 0px 0px 5px" },
                  }}
                >
                  <Box>
                    <Box
                      sx={{ marginLeft: "-5px" }}
                      display={{ xs: "none", md: "flex" }}
                      alignItems={"center"}
                      gap={0}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000",
                          width: { lg: "100px", md: "100px" },
                        }}
                      >
                        To Doctor
                      </FormLabel>
                      <BasicSelect
                        sx={{
                          width: { xs: "160px", sm: "180px" },
                          height: "30px",
                          "& .MuiSelect-select": { fontSize: "13px" },
                        }}
                        value={selectedDoctor || "Select"}
                        onChange={handleChange}
                        displayEmpty
                      >
                        <BasicMenuItem
                          value="Select"
                          disabled
                          sx={{ display: "none" }}
                        >
                          <span style={{ color: "#bbb7b7" }}>Select</span>
                        </BasicMenuItem>
                        {Array.isArray(filteredUsersForToDoctor) &&
                        filteredUsersForToDoctor.length > 0 ? (
                          filteredUsersForToDoctor.map((val, i) => (
                            <MenuItem key={i} value={val}>
                              <ListItemText
                                primary={val.full_name}
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    fontSize: "13px",
                                  },
                                }}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No doctors available</MenuItem>
                        )}
                      </BasicSelect>

                      {/* <TextField type='date' value={value} readOnly size='small'/> */}
                    </Box>
                    <Box
                      sx={{
                        marginTop: "4%",
                        marginLeft: { xs: "-5px", sm: "-5px", md: "" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000",
                        }}
                      >
                        Total Appointments :
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#000",
                            marginLeft: "3px",
                            marginTop: "10px",
                          }}
                        >
                          {cancelData.queCount + cancelData.notarrivedCount}
                        </span>
                      </Typography>
                    </Box>
                    {/* <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        xs={12}
                        sm={12}
                        sx={{
                          marginLeft: { xs: "-6px", sm: "-6px", md: "" },
                        }}
                      >
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                            <Box >
                              <FormControl style={{ marginRight: "2%", marginTop: "4%" }}>
                                <Button
                                  variant="outlined"
                                  color="warning"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    width: "140px",
                                    height: "40px",
                                    padding: "4px 4px",
                                    textTransform: "none",
                                    cursor: "default",
                                  }}
                                >
                                  Patients in Que- {cancelData.queCount}
                                </Button>
                              </FormControl>
                              <FormControl sx={{marginTop: "4%"}} >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                    width: "160px",
                                    height: "40px",
                                    padding: "4px 4px",
                                    textTransform: "none",
                                    cursor: "default",
                                  }}
                                >
                                  Patients not Arrived -{" "}
                                  {cancelData.notarrivedCount}
                                </Button>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid> */}
                    <Grid
                      sx={{
                        marginTop: "4%",
                        marginLeft: { xs: "-5px", sm: "-5px", md: "" },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          {`Appointments for the patients moved to ${selectedDoctor.full_name}.`}
                        </Typography>
                        {/* {cancelData.notarrivedCount > 0 && (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#000",
                                marginTop: "2%",
                              }}
                            >
                              SMS or WhatsApp notification will be sent to{" "}
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                {cancelData.notarrivedCount} patient(s).
                              </span>
                            </Typography>
                          )} */}
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "2%",
                        marginLeft: { xs: "-6px", sm: "-6px", md: "" },
                      }}
                    >
                      <Grid>
                        <FormLabel
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          Reason for cancelation : (optional)
                        </FormLabel>
                        <TextField
                          fullWidth
                          // sx={{width:{md:"100%", xs:"50%"}}}
                          onChange={(e) => setResonMessage(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ marginLeft: { xs: "5px", sm: "5px", md: "" } }}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Button
                        type="submit"
                        sx={{
                          mt: 3,
                          mb: 2,
                          mr: 2,
                          backgroundColor: "#D13C3C",
                          color: "#FFFFFF",
                          border: "1px solid #FFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { background: "#D13C3C" },
                          width: "154px",
                          textTransform: "none",
                        }}
                        onClick={() => cancelModel("CANCEL")}
                      >
                        Cancel
                      </Button>
                      {/* <Button
                              disabled={submitValidation}
                              onClick={() => cancelModel("SUBMIT")}
                              type="submit"
                              sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor:submitValidation === true ?  "rgba(0, 0, 0, 0.5)" : "#41BA8F",
                                color: "#FFFFFF",
                                border: "1px solid #FFF",
                                borderRadius: "50px",
                                height: "40px",
                                "&:hover": { background: "#41BA8F" },
                                width: "154px",
                                textTransform: "none",
                              }}
                            >
                              Submit
                            </Button> */}

                      <CustomizedButtons
                        onClick={() => cancelModel("BULK_SUBMIT")}
                        disabled={submitValidation}
                        bgColor={"#41BA8F"}
                        color={"white"}
                        hoverColor={"#41BA8F"}
                        text={"Submit"}
                        style={{
                          mt: 3,
                          mb: 2,
                          fontSize: "14px",
                          borderRadius: "50px",
                          width: "154px",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={successModal}
      >
        <Backdrop
          open={successModal}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper sx={{ width: "350px", borderRadius: "10px" }}>
            <Box sx={{  borderRadius: "10px" }}>
              <Box
                sx={{
                  height: "60px",
                  backgroundColor: "#FFD7D7",
                  borderRadius: "10px 10px 0px 0px",
                }}
              >
                <Box sx={{ textAlign: "center", paddingTop: "40px" }}>
                  <img
                    src={cancellIcon}
                    alt="deleteicon"
                    style={{ width: "40px" }}
                  />
                </Box>
              </Box>
              <Box sx={{ margin: 5, marginBottom: 2 }}>
                <Typography
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  <span style={{ width: "165px" }}>Appointments moved</span>:<span style={{fontWeight: 450}}>&nbsp;{appointmentSuccessData.moved}</span>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  <span style={{ width: "165px" }}>Appointments unmoved</span>:<span style={{fontWeight: 450}}>&nbsp;{appointmentSuccessData.unmoved}</span>
                </Typography>
              </Box>

              <Box sx={{ textAlign: "center", marginBottom: 2 }}>
                <Button
                  sx={{
                    backgroundColor: "#41BA8F",
                    color: "#FFFFFF",
                    height: "25px",
                    "&:hover": { background: "#41BA8F" },
                    width: "95px",
                    fontSize: "13px",
                    fontWeight: 600,
                    borderRadius: "30px",
                    textTransform: "none",
                  }}
                  onClick={handleSuccessCloseModal}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </Paper>
        </Backdrop>
      </Modal>
      {cancelBulkModal && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
          popupType={popupType}
          submitCancel={submitCancel}
        />
      )}
    </>
  );
}
